import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import client from '../client'
import imageUrlBuilder from '@sanity/image-url'
import { PortableText } from '@portabletext/react'

import { IoLogoWhatsapp } from 'react-icons/io5'
import { LiaTelegramPlane } from 'react-icons/lia'
import SwitchImage from '../hooks/SwitchImage'
// import mobileVideo from '../assets/videos/cocktailMobile.mp4'
// import desktopVideo from '../assets/videos/agencyDesktop.mp4'
// import SwitchVideo from '../hooks/SwitchVideo'

const builder = imageUrlBuilder(client)
function urlFor(source) {
  return builder.image(source)
}

export default function Agency() {
  const [singleAgency, setSingleAgency] = useState(null)
  const { slug } = useParams()

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == '${slug}']{
           agencyName,
           _id,
           slug,
           desktopImage{asset->{_id,url}},
           cardImage{asset->{_id,url}},
           mainContent,
           telegramUrl,
           whatsappUrl,
           conditionsBanner,
           conditionItems
      }`
      )
      .then((data) => setSingleAgency(data[0]))
      .catch(console.error)
  }, [slug])

  if (!singleAgency) return <div>loading...</div>

  return (
    <section>
      <div className='relative w-full h-screen'>
        <SwitchImage
          mobile={urlFor(singleAgency.cardImage).url()}
          desktop={urlFor(singleAgency.desktopImage).url()}
          style={`bg-black object-cover w-full h-full`}
        />
        {/* <SwitchVideo
          mobile={mobileVideo}
          desktop={desktopVideo}
        /> */}
        {/* <div className='absolute inset-0 flex flex-col justify-center items-center bg-gradient-to-t from-black via-black/10 to-black'></div> */}
        <div className='absolute bottom-0 md:bottom-12 left-1/2 -translate-x-1/2 -translate-y-1/2  w-full z-40'>
          <div className='hidden sm:flex flex-col md:flex-row justify-center gap-8 lg:gap-32 items-center text-white text-3xl sm:text-5xl z-50 mb-12 sm:mb-0'>
            <h1 className='dropshadow-md'>
              <Link
                to={singleAgency.telegramUrl}
                className='transition-all hover:text-gray-600 duration-500'
              >
                TELEGRAM
              </Link>
            </h1>
            <h1 className='dropshadow-md'>
              <Link
                to={singleAgency.whatsappUrl}
                className='transition-all hover:text-gray-600  duration-500'
              >
                WHATSAPP
              </Link>
            </h1>
          </div>
          <div className='sm:hidden flex flex-row items-center justify-center gap-28 px-4 mb-6 text-white'>
            <Link
              to={singleAgency.whatsappUrl}
              className='p-5 bg-white/20 backdrop-blur-lg rounded-full'
            >
              <IoLogoWhatsapp size={30} />
            </Link>
            <Link
              to={singleAgency.telegramUrl}
              className='p-5 bg-white/20 backdrop-blur-lg rounded-full'
            >
              <LiaTelegramPlane size={30} />
            </Link>
          </div>
        </div>
        <h1 className='absolute inset-0 text-center flex flex-col justify-center text-6xl md:text-[120px] lg:text-[180px] text-white drop-shadow-md'>
          {singleAgency.agencyName}
        </h1>
      </div>
      <section className='w-full px-4 sm:w-5/6 md:w-3/4 lg:w-1/2 mx-auto text-center indent-8'>
        <h1 className='text-zinc-900 font-medium text-2xl md:text-3xl py-12  sm:py-24 border-b-[1px]'>
          <PortableText value={singleAgency.mainContent} />
        </h1>
      </section>
      <div className='w-full px-4 py-12 flex flex-col justify-center items-center'>
        <h1 className='text-4xl sm:text-6xl text-center font-medium drop-shadow-md'>
          {singleAgency.conditionsBanner}
        </h1>
        <div className='flex flex-col items-center justify-center pt-12'>
          {singleAgency.conditionItems?.map((condition, idx) => (
            <h1
              key={idx}
              className='text-2xl text-center sm:text-3xl font-bold sm:w-2/3'
            >
              {condition}
            </h1>
          ))}
        </div>
      </div>
      <div className='py-12 hidden sm:flex flex-row gap-8 md:gap-56 justify-center items-center'>
        <h1 className='dropshadow-md text-2xl md:text-4xl font-medium'>
          <Link
            to={singleAgency.telegramUrl}
            className='transition-all sm:hover:text-gray-400  duration-500'
          >
            TELEGRAM
          </Link>
        </h1>
        <h1 className='dropshadow-md text-2xl md:text-4xl font-medium'>
          <Link
            to={singleAgency.whatsappUrl}
            className='transition-all sm:hover:text-gray-400  duration-500'
          >
            WHATSAPP
          </Link>
        </h1>
      </div>
      <div className='sm:hidden flex flex-row items-center justify-center gap-28 px-4 py-12 text-black'>
        <Link
          to={singleAgency.whatsappUrl}
          className='p-5 border-[1px] shadow-md bg-white/20 backdrop-blur-lg rounded-full'
        >
          <IoLogoWhatsapp size={30} />
        </Link>
        <Link
          to={singleAgency.telegramUrl}
          className='p-5 border-[1px] shadow-md bg-white/20 backdrop-blur-lg rounded-full'
        >
          <LiaTelegramPlane size={30} />
        </Link>
      </div>
    </section>
  )
}
