import { createClient } from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

const client = createClient({
  projectId: 'j6g4qi1w',
  dataset: 'production',
  apiVersion: '2024-05-03',
  useCdn: true,
})

export default client

const builder = imageUrlBuilder(client)

export const urlFor = (source) => builder.image(source)
