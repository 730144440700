import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import client from '../client'
import imageUrlBuilder from '@sanity/image-url'
import { PortableText } from '@portabletext/react'

import { IoLogoWhatsapp } from 'react-icons/io5'
import { LiaTelegramPlane } from 'react-icons/lia'
import mobileVideo from '../assets/videos/agencyMobile.mp4'
import desktopVideo from '../assets/videos/agencyDesktop.mp4'
import SwitchVideo from '../hooks/SwitchVideo'
import { FaMapMarkerAlt } from 'react-icons/fa'

const builder = imageUrlBuilder(client)
function urlFor(source) {
  return builder.image(source)
}

export default function VipAgency() {
  const [singleVipAgency, setSingleVipAgency] = useState(null)
  const { slug } = useParams()

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == '${slug}']{
           agencyName,
           _id,
           slug,
           desktopImage{asset->{_id,url}},
           cardImage{asset->{_id,url}},
           flagImage[]{asset->{_id,url}},
           avatarImage{asset->{_id,url}},
           agencyCountries,
           mainContent,
           telegramUrl,
           whatsappUrl,
           conditionsBanner,
           conditionItems
      }`
      )
      .then((data) => setSingleVipAgency(data[0]))
      .catch(console.error)
  }, [slug])

  if (!singleVipAgency) return <div>loading...</div>
  return (
    <>
      <section className='w-full bg-black h-screen relative overflow-hidden'>
        <SwitchVideo
          mobile={mobileVideo}
          desktop={desktopVideo}
        />
        <div className='absolute left-0 top-12 px-4 py-2 sm:py-2 bg-gradient-to-r from-red-600 to-rose-500'>
          <h1 className='text-white font-medium tracking-widest text-2xl sm:text-4xl'>
            TOP
          </h1>
        </div>
        <div className='absolute inset-0 text-center flex flex-col gap-2 justify-center'>
          <h1 className=' text-6xl sm:text-6xl md:text-[100px] lg:text-[120px] xl:text-[160px] 2xl:text-[180px] text-white font-light px-8'>
            {singleVipAgency.agencyName}
          </h1>
          <div className='w-full flex flex-row gap-24 md:gap-32 justify-center items-center'>
            <Link
              to={singleVipAgency.whatsappUrl}
              className='p-5 shadow-lg bg-white/20 backdrop-blur-md rounded-full text-white transition-all duration-300 hover:bg-green-400'
            >
              <IoLogoWhatsapp size={30} />
            </Link>
            <Link
              to={singleVipAgency.telegramUrl}
              className='p-5 shadow-lg bg-white/20 backdrop-blur-md rounded-full text-white transition-all duration-300 hover:bg-blue-400'
            >
              <LiaTelegramPlane size={30} />
            </Link>
          </div>
        </div>
        <div className='absolute bottom-8 sm:bottom-12 left-2 sm:left-12 flex items-end gap-2 sm:gap-4 rounded-full'>
          <img
            src={urlFor(singleVipAgency.avatarImage).url()}
            alt='cup-avatar'
            className='w-24 h-24 rounded-full object-cover shadow-md shadow-zinc-900 sm:shadow-black'
          />
          <div className='flex items-center gap-2 sm:gap-4'>
            {singleVipAgency.flagImage.map((flag, idx) => (
              <img
                key={idx}
                src={flag.asset.url}
                alt='flag_img'
                className='w-12 sm:w-16 h-10 sm:h-12 object-cover shadow-sm shadow-black mb-2 sm:mb-1'
              />
            ))}
          </div>
        </div>
      </section>
      <section className='w-full h-full bg-black'>
        <div className='w-full h-full px-4 sm:w-5/6 md:w-3/4 lg:w-2/3 mx-auto text-center '>
          <div className='flex flex-col sm:flex-row justify-center items-center pt-12 px-2 text-white font-medium text-4xl sm:text-6xl gap-2 sm:gap-6'>
            <FaMapMarkerAlt size={32} />
            {singleVipAgency.agencyCountries?.map((country, idx) => (
              <h1 key={idx}>{country}</h1>
            ))}
          </div>
          <h1 className='text-neutral-400 font-medium text-2xl md:text-5xl py-12 border-b-[1px] border-zinc-900'>
            <PortableText value={singleVipAgency.mainContent} />
          </h1>
        </div>
      </section>
      <section className='px-4 w-full h-full bg-black py-12 flex flex-col justify-center items-center text-white'>
        <h1 className='text-3xl md:text-5xl sm:text-6xl text-center font-medium drop-shadow-md tracking-wider'>
          {singleVipAgency.conditionsBanner}
        </h1>
        <div className='flex flex-col items-center justify-center pt-6 sm:pt-12  border-b-[1px] border-zinc-900'>
          {singleVipAgency.conditionItems?.map((condition, idx) => (
            <h1
              key={idx}
              className='text-xl md:text-4xl xl:text-5xl text-center text-neutral-400 font-bold tracking-wider'
            >
              {condition}
            </h1>
          ))}
        </div>
      </section>
    </>
  )
}
