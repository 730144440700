import SwitchVideo from '../hooks/SwitchVideo'
import mobileVideo from '../assets/videos/main_mobile.mp4'
import desktopVideo from '../assets/videos/main_desktop.mp4'
import { Link } from 'react-router-dom'

export default function HomeVideoBanner() {
  return (
    <section>
      <div className='bg-black h-screen relative -z-50 overflow-hidden'>
        <SwitchVideo
          mobile={mobileVideo}
          desktop={desktopVideo}
        />
      </div>
      <nav className='absolute inset-0 text-center flex flex-col gap-2 justify-center text-6xl sm:text-6xl md:text-[100px] lg:text-[120px] xl:text-[160px] text-white font-light'>
        <h1>
          <Link
            to='/offers'
            className=' hover:text-gray-400 transition-all duration-500'
          >
            OFFERS
          </Link>
        </h1>
        <h1>
          <Link
            to='/vacancies'
            className='hover:text-gray-400 transition-all duration-500'
          >
            COUNTRIES
          </Link>
        </h1>
        <h1>
          <Link
            to='/groups'
            className='hover:text-gray-400 transition-all duration-500'
          >
            GROUPS
          </Link>
        </h1>
      </nav>
    </section>
  )
}
