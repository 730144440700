import { Route, Routes } from 'react-router-dom'
import HomeVideoBanner from './components/HomeVideoBanner'
import { Suspense, lazy } from 'react'
import Agency from './pages/Agency'
import VipAgency from './pages/VipAgency'
import CountryAgencies from './pages/CountryAgencies'

const Offers = lazy(() => import('./pages/Offers'))
const Vacancies = lazy(() => import('./pages/Vacancies'))
const Groups = lazy(() => import('./pages/Groups'))

function App() {
  return (
    <>
      <Routes>
        <Route
          path='/'
          element={<HomeVideoBanner />}
        />
        <Route
          path='/offers'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Offers />
            </Suspense>
          }
        />
        <Route
          path='/offers/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <VipAgency />
            </Suspense>
          }
        />
        <Route
          path='/vacancies'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Vacancies />
            </Suspense>
          }
        />
        <Route
          path='/vacancies/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <CountryAgencies />
            </Suspense>
          }
        />
        <Route
          path='/vacancies/:slug/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Agency />
            </Suspense>
          }
        />
        <Route
          path='/groups'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Groups />
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}

export default App
