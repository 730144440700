import { useState, useEffect } from 'react'
import client from '../client'
import { Link, useParams } from 'react-router-dom'
import SwitchImage from '../hooks/SwitchImage'
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(client)
function urlFor(source) {
  return builder.image(source)
}

export default function CountryAgencies() {
  const [country, setCountry] = useState(null)
  const { slug } = useParams()

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == '${slug}']{
        _id,
        countryName,
        desktopImage,
        cardImage,
        mobileImage,
        slug,
        avatarImage{asset->{_id,url}},
        relatedAgencies[]->{
        agencyName,
        slug,
        cardImage{asset->{_id,url}},
        mobileImage{asset->{_id,url}}
        }
        }`
      )
      .then((data) => setCountry(data[0]))
      .catch(console.error)
  }, [slug])

  if (!country) return <div>loading...</div>

  return (
    <section className='min-h-screen'>
      <div className='relative bg-black flex flex-col items-center justify-center w-full h-screen'>
        <SwitchImage
          mobile={urlFor(country.mobileImage).url()}
          desktop={urlFor(country.desktopImage).url()}
          style={`bg-black object-cover w-full h-full`}
        />
        <div className='absolute inset-0 flex flex-col items-center justify-center'>
          <h1 className='text-white sm:font-normal font-medium text-6xl sm:text-8xl md:text-9xl xl:text-[180px] drop-shadow-md'>
            AGENCIES
          </h1>
        </div>
        <div className='absolute top-12 left-2 sm:left-4 flex items-center text-3xl sm:text-5xl text-white font-light sm:font-normal'>
          <div className='flex items-center gap-2 sm:gap-4'>
            <img
              src={urlFor(country.avatarImage).url()}
              alt='country_avatar'
              className='items-start w-12 sm:w-16 sm:h-16 h-12 shadow-md'
            />
            <h1 className='text-white text-3xl sm:font-normal font-medium sm:text-5xl  drop-shadow-lg'>
              {country.countryName}
            </h1>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2 sm:gap-4 px-2 md:px-4 pb-2 sm:pb-4 pt-2 sm:pt-4 z-20'>
        {country.relatedAgencies?.map((agency, idx) => (
          <Link
            key={idx}
            to={agency.slug.current}
            className='transition-all duration-500 sm:hover:opacity-80'
          >
            <div className='relative w-full h-full'>
              <div className='-z-50 h-[240px] sm:h-[450px] md:h-[600px] w-full object-fit object-cover bg-zinc-900'>
                <SwitchImage
                  desktop={urlFor(agency.cardImage).url()}
                  mobile={urlFor(agency.mobileImage).url()}
                  style={`bg-black object-cover w-full h-full`}
                />
              </div>
              <div className='absolute inset-0 pt-2 sm:pt-6  flex flex-col items-center text-white'>
                <h1 className='text-xl md:text-4xl bg-gray/50 backdrop-blur-sm mx-auto px-2 sm:pb-2 text-center w-2/3'>
                  {agency.agencyName}
                </h1>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  )
}
