import { Link } from 'react-router-dom'
import { motion, useCycle, AnimatePresence } from 'framer-motion'
import video from '../assets/videos/cup.mp4'

const navlinks = [
  { title: 'OFFERS', href: '/offers' },
  { title: 'COUNTRIES', href: '/vacancies' },
  { title: 'GROUPS', href: '/groups' },
]

export default function AnimatedNavbar() {
  const [mobileNav, toggleMobileNav] = useCycle(false, true)
  return (
    <header className='fixed top-0 w-full h-9 shadow-md bg-black z-50 '>
      <div className='px-2 md:px-4'>
        <div className='flex flex-row items-center align-middle justify-between py-1'>
          <Link to='/'>
            <div className='flex items-center group'>
              <h1 className='text-lg text-center text-gray-200 tracking-widest font-extralight group-hover:text-gray-400 transition-all duration-500'>
                COCKTAIL
              </h1>
              <video
                className='bg-black w-8 h-full object-cover  pointer-events-none select-none pb-2'
                loop
                autoPlay
                muted
                playsInline
                controls={false}
                preload='auto'
              >
                <source src={video} />
              </video>
              <h1 className='-ml-1 text-white font-normal text-lg tracking-widest group-hover:text-gray-400 transition-all duration-500'>
                GROUPS
              </h1>
            </div>
          </Link>
          <div className='relative z-50'>
            <motion.button
              animate={mobileNav ? 'open' : 'closed'}
              onClick={() => toggleMobileNav()}
              className='flex flex-col space-y-1'
            >
              <motion.span
                variants={{
                  closed: { rotate: 0, y: 0 },
                  open: { rotate: 45, y: 6 },
                }}
                className='w-5 h-[2px] rounded-full bg-white block'
              />
              <motion.span
                variants={{
                  closed: { opacity: 1 },
                  open: { opacity: 0 },
                }}
                className='w-5 h-[2px] rounded-full bg-white block'
              />
              <motion.span
                variants={{
                  closed: { rotate: 0, y: 0 },
                  open: { rotate: -45, y: -6 },
                }}
                className='w-5 h-[2px] rounded-full bg-white block'
              />
            </motion.button>
          </div>
          <AnimatePresence>
            {mobileNav && (
              <motion.div
                key='mobile-nav'
                variants={{
                  open: { x: '0%' },
                  closed: { x: '-100%' },
                }}
                initial='closed'
                animate='open'
                exit='closed'
                className='fixed inset-0 bg-gray/70 backdrop-blur-xl z-40'
              >
                <nav
                  onClick={() => toggleMobileNav()}
                  className='flex flex-col justify-center items-center gap-4 sm:gap-3 h-screen w-full text-white text-6xl lg:text-9xl 2xl:text-[200px] font-light'
                >
                  {navlinks.map(({ href, title }) => {
                    return (
                      <Link
                        key={title}
                        to={href}
                        className='hover:text-gray-400 transition-all'
                      >
                        {title}
                      </Link>
                    )
                  })}
                </nav>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </header>
  )
}
