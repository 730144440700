import { Link } from 'react-router-dom'
import video from '../assets/videos/cup.mp4'

export default function Footer() {
  return (
    <footer className='bg-black text-white flex flex-col justify-center items-center'>
      <Link to='/'>
        <div className='flex items-center group'>
          <div className='pr-6 py-3 flex border-b-[1px] border-zinc-900'>
            <h1 className='text-lg text-center text-gray-200 tracking-widest font-extralight group-hover:text-gray-400 transition-all duration-500'>
              COCKTAIL
            </h1>
            <video
              className='bg-black w-8 h-full object-cover  pointer-events-none select-none pb-2'
              loop
              autoPlay
              muted
              playsInline
              controls={false}
              preload='auto'
            >
              <source src={video} />
            </video>
            <h1 className='-ml-1 text-white font-normal text-lg tracking-widest group-hover:text-gray-400 transition-all duration-500'>
              GROUPS
            </h1>
          </div>
        </div>
      </Link>
      <div className='w-full flex flex-col md:flex-row items-center justify-between px-4 pb-2'>
        <h1 className='text-[8px] font-light'>
          &copy; 2024 Cocktail Groups, All rights reserved
        </h1>
        <h1 className='text-[8px] font-light'>
          Cocktail Groups does not offer intimate services. All vacancies for
          girls over 18 years old
        </h1>
      </div>
    </footer>
  )
}
